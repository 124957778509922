import React from "react";
import './Contact.css';
import axios from 'axios';
import {Container} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Recaptcha from "react-google-recaptcha";
import {withTranslation} from "react-i18next";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.handleVerification = this.handleVerification.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.state = {
            name: '',
            email: '',
            message: '',
            isVerified: false,
            lang: this.props.i18n.language,
            nameError: '',
            emailError: '',
            messageError:'',
            captchaSize:""
        }
    }

    handleVerification(){
        const {t} = this.props;
        if(!this.state.isVerified){
            alert(t('contact.captcha'));
        }
    }

    recaptchaLoaded(){
        console.log('Recaptcha component loaded.');
    }

    verifyCallback(response){
        if(response){
            this.setState({isVerified: true})
        }
    }

    expiredCallback(){
        this.setState({isVerified: false})
        //alert('Verification expired. Check the checkbox again.');
    }

    handleSubmit(e) {
        const isValidForm = this.validate();
        this.handleVerification();
        if(this.state.isVerified && isValidForm) {
            e.preventDefault();
            const url = '/api/sendmail';
            console.log('Post to ' + url);

            axios.post(
                url,
                this.state
            ).then((response) => {
                console.log("Result " + response.data)
                console.log("Result " + response.data.result)

                if (response.data.result === 'success') {
                    alert("Message Sent.");
                    this.resetForm()
                } else {
                    alert("Message failed to send.")
                }
            }).catch((err) => {
                console.log("Error " + err.response.status)
                alert("Message failed to send.")
            })
        }
    }

    validate = () =>{
        const {t} = this.props;
        this.setState({nameError:'',emailError:'',messageError:''});
        let nameError= "";
        let emailError= "";
        let messageError="";
        if(!this.state.name){
            nameError = t('contact.emptyName');
        }
        if(!this.state.email){
            emailError = t('contact.emptyEmail');
        }else{
            // eslint-disable-next-line no-useless-escape
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.email) ) {
                emailError = t('contact.invalidEmail');
            }

        }
        if(!this.state.message){
            messageError = t('contact.emptyMessage');
        }
        if(nameError || emailError || messageError){
            this.setState({nameError,emailError,messageError})
            return false;
        }
        return true;
    }

    resetForm() {
        this.setState({name: '', email: '', message: ''})
    }

    render() {
        const {t} = this.props;
        const recaptchaRef = React.createRef();
        let captchaSize;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            captchaSize = 'compact'
        }else{
            captchaSize = 'normal'
        }
        console.log('captchaSize:' + captchaSize);
        return (
            <section id="contact" className="contact">
                <Fade left small>
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d290.4460596815364!2d-58.46888319555629!3d-34.552996993133924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb68355fe55f3%3A0x2aba32ef7b444110!2sBPMX%20Perts!5e0!3m2!1ses!2sar!4v1608315778347!5m2!1ses!2sar"
                            width="100%" height="500" frameBorder="0" allowFullScreen=""
                            aria-hidden="false" tabIndex="0" title="BPMX"
                        />
                    </div>
                    <div className="offset-top">
                        <Container>
                            <div className="row space-100">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="contact-block">
                                        <form id="contact-form">
                                            <h2>{t('contact.title')}</h2>
                                            <div className="form-group">
                                                <label htmlFor="name">{t('contact.name')}</label>
                                                <input type="text" className="form-control" id="name"
                                                       value={this.state.name}
                                                       onChange={this.onNameChange.bind(this)}/>
                                            </div>
                                            <div className="form-group" style={{fontSize:12, color:"red"}}>
                                                {this.state.nameError}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">{t('contact.email')}</label>
                                                <input type="email" className="form-control" id="email"
                                                       aria-describedby="emailHelp" value={this.state.email}
                                                       onChange={this.onEmailChange.bind(this)}/>
                                            </div>
                                            <div className="form-group" style={{fontSize:12, color:"red"}}>
                                                {this.state.emailError}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="message">{t('contact.message')}</label>
                                                <textarea className="form-control" rows="5" id="message"
                                                          value={this.state.message}
                                                          onChange={this.onMessageChange.bind(this)}/>
                                            </div>
                                            <div className="form-group" style={{fontSize:12, color:"red"}}>
                                                {this.state.messageError}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="button"
                                                    value={t('contact.send')}
                                                    className="btn btn-border"
                                                    onClick={this.handleSubmit.bind(this)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Recaptcha
                                                    ref={recaptchaRef}
                                                    sitekey="6LcfMCYaAAAAAOSdlIgFfMb5ijt0QbtlWkDvBTD1"
                                                    render="explicit"
                                                    onChange={this.verifyCallback}
                                                    onExpired={this.expiredCallback}
                                                    size={captchaSize}
                                                />
                                            </div>
                                        </form>
                                        {/*<ContactForm/>*/}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Fade>
                {/*</div>*/}
            </section>
        )
            ;
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}

export default withTranslation()(Contact);