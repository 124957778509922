import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from "react-i18next";


import 'bootstrap/dist/css/bootstrap.css';
import './Fonts.css';
import './Colors.css';
import './Index.css';
import './utils/init-firebase.js'
import i18n from "./utils/i18n.js"
import App from './App';

import * as serviceWorker from './utils/serviceWorker';


ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
