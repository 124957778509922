import React from 'react';
import {withTranslation} from "react-i18next";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {scroller} from "react-scroll";

import './NavigationBar.css';

import logo from '../assets/logos/logo_principal.png';
import isologo from '../assets/logos/isologo.png';
import language from '../assets/img/language.png';

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "navbar-transparent",
            lang: this.props.i18n.language
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let aboutRef = document.getElementById("aboutus");
        let servicesRef = document.getElementById("services");
        let clientsRef = document.getElementById("clients");
        let contactRef = document.getElementById("contact");

        let navhomeRef = document.getElementById("nav-home");
        let navaboutusRef = document.getElementById("nav-aboutus");
        let navservicesRef = document.getElementById("nav-services");
        let navclientsRef = document.getElementById("nav-clients");
        let navcontactRef = document.getElementById("nav-contact");

        navhomeRef.classList.remove("active");
        navaboutusRef.classList.remove("active");
        navservicesRef.classList.remove("active");
        navclientsRef.classList.remove("active");
        navcontactRef.classList.remove("active");

        if (window.scrollY >= 0 && window.scrollY <= window.innerHeight / 2 && (aboutRef == null || aboutRef.offsetTop - window.scrollY >= window.innerHeight / 2)) {
            navhomeRef.classList.add("active");
        } else if (aboutRef.offsetTop - window.scrollY < window.innerHeight / 2 && (servicesRef == null || servicesRef.offsetTop - window.scrollY >= window.innerHeight / 2)) {
            navaboutusRef.classList.add("active");
        } else if (servicesRef.offsetTop - window.scrollY < window.innerHeight / 2 && (clientsRef == null || clientsRef.offsetTop - window.scrollY >= window.innerHeight / 2)) {
            navservicesRef.classList.add("active");
        } else if (clientsRef.offsetTop - window.scrollY < window.innerHeight / 2 && (contactRef == null || contactRef.offsetTop - window.scrollY >= window.innerHeight / 2)) {
            navclientsRef.classList.add("active");
        } else if (contactRef.offsetTop - window.scrollY < window.innerHeight / 2) {
            navcontactRef.classList.add("active");
        }

        let navColor;
        if (window.scrollY >= aboutRef.offsetTop - 150) navColor = "navbar-background";
        else navColor = "navbar-transparent";

        let sticky;
        if (window.scrollY > 80) sticky = " menu-bg";
        else sticky = "";

        this.setState({color: navColor + " " + sticky});
    }

    scrollToSection(section) {
        return () => scroller.scrollTo(section, {
            smooth: true,
            offset: -80,
            duration: 800,
        });
    }

    changeLanguage(lang) {
        this.props.i18n.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        this.setState({lang: lang});
    }

    render() {
        const {t} = this.props;
        return (
            <Navbar collapseOnSelect className={"fixed-top " + this.state.color} expand="md" bg="inverse"
                    variant="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Brand
                    href="/"
                    onClick={this.scrollToSection('home')} className="page-scroll navbar-logo">
                    <img src={logo} height="55" alt="BPMX - Rethinking your process" className="hidden-mobile"/>
                    <img src={isologo} height="55" alt="BPMX - Rethinking your process" className="show-mobile"/>
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Container>
                        <Nav className="mr-auto full-width flex-end">
                            <Nav.Item>
                                <Nav.Link active id='nav-home'
                                          href="/"
                                          onClick={this.scrollToSection('home')}>{t('menu.home')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id='nav-aboutus'
                                          href="/#aboutus"
                                          onClick={this.scrollToSection('aboutus')}>{t('menu.aboutus')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id='nav-services'
                                          href="/#services"
                                          onClick={this.scrollToSection('services')}>{t('menu.services')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id='nav-clients'
                                          href="/#clients"
                                          onClick={this.scrollToSection('clients')}>{t('menu.clients')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id='nav-contact'
                                          href="/#contact"
                                          onClick={this.scrollToSection('contact')}>{t('menu.contact')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <NavDropdown id="basic-nav-dropdown" title={
                                    <div style={{display: "inline-block"}}>
                                        <img className="language" src={language} alt="BPMX"/>
                                        {this.state.lang === "es" ? t('menu.spanish') : t('menu.english')}
                                    </div>
                                }>
                                    <NavDropdown.Item id="basic-nav-dropdown-item-es"
                                                      onClick={() => this.changeLanguage("es")}><img src={language}
                                                                                                     height="16"
                                                                                                     className="language"
                                                                                                     alt="BPMX"/>{t('menu.spanish')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item id="basic-nav-dropdown-item-en"
                                                      onClick={() => this.changeLanguage("en")}><img src={language}
                                                                                                     height="16"
                                                                                                     className="language"
                                                                                                     alt="BPMX"/>{t('menu.english')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withTranslation()(NavigationBar);