import React from "react";
import {Container} from "react-bootstrap";
import Fade from 'react-reveal/Fade';

import './AboutUs.css';
import {useTranslation} from "react-i18next";
import aboutus_image from '../assets/img/personal_data.png';

const AboutUs = () => {
    const {t} = useTranslation();
    return (

        <section id="aboutus" className="aboutus">
            <Container>
                <div className="row space-100">
                    <Fade left small>
                        <div className="col-lg-7 col-md-12 col-xs-12">
                            <div className="contents">
                                <div className="contents-text">
                                    <h2>{t("aboutus.title")}</h2>
                                    <h4>{t("aboutus.subtitle")}</h4>
                                    <h6>{t("aboutus.subtitle2")}</h6>
                                    <h6>{t("aboutus.description1")}</h6>
                                    <h6>{t("aboutus.description2")}</h6>
                                    <h6>{t("aboutus.description3")}</h6>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <div className="col-lg-5 hidden-small">
                        <Fade right small>
                            {/*<div className="contents">*/}
                            <div className="intro-img">
                                <img src={aboutus_image} alt=""/>
                            </div>
                            {/*</div>*/}
                        </Fade>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export {AboutUs as default};