// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
var firebaseConfig = {
    apiKey: "AIzaSyC8dusq3lX24nhR5y0tQUSIIi15PXTO6pU",
    authDomain: "bpmx-website.firebaseapp.com",
    projectId: "bpmx-website",
    storageBucket: "bpmx-website.appspot.com",
    messagingSenderId: "785034492647",
    appId: "1:785034492647:web:a2850f515a637a8a08c042",
    measurementId: "G-XQF6H7M116"
};

// Initialize Firebase with a "default" Firebase project
var defaultProject = firebase.initializeApp(firebaseConfig);

console.log("firebase " + defaultProject.name);  // "[DEFAULT]"

const defaultAnalytics = defaultProject.analytics();
defaultAnalytics.setAnalyticsCollectionEnabled(true);
defaultAnalytics.logEvent('notification_received');

console.log("firebaseAnalytics " + defaultAnalytics.name);