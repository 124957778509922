import React from "react";
import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import {Link} from 'react-scroll';

import './Home.css';

import logo from '../assets/logos/logo_tag_secundario.png';
import home_image from '../assets/img/innovation.png';

export const Home = () => {
    const {t} = useTranslation();
    return (
        <section id="home" className="home">
            <Container>
                <div className="row space-100">
                    <div className="col-lg-7 col-md-12 col-xs-12">
                        <div className="contents">
                            <div className="logo-img">
                                <img src={logo} alt="BPMX - Rethinking your process"/>
                            </div>
                            {/*<h2>{t("home.title")}</h2>*/}
                            <h1>{t("home.subtitle")}</h1>
                            <br/>
                            <div className="header-button">
                                <Link className="btn btn-border" to="contact" smooth={true}
                                      duration={1000}>{t("home.button-contact")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-xs-12 hidden-small">
                        <div className="intro-img">
                            <img src={home_image}
                                 alt="Business Software development | BPM | Java | React js | Flutter"/>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}