import React, {lazy, Suspense} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import './App.css';

import logo from "./assets/logos/logo_tag_secundario.png";
import Particles from "react-tsparticles";
import NavigationBar from "./sections/NavigationBar";
import particlesOptions from "./utils/particles.json";
import {Home} from "./sections/Home";
import AboutUs from "./sections/AboutUs";
import Contact from "./sections/Contact";
import NavigationBarSimple from "./sections/NavigationBarSimple";

const AboutUsLazy = lazy(() => import('./sections/AboutUs'));
const ServicesLazy = lazy(() => import('./sections/Services'));
const ClientsLazy = lazy(() => import('./sections/Clients'));
const ContactLazy = lazy(() => import('./sections/Contact'));
const Footer = lazy(() => import('./sections/Footer'));
const ScrollArrow = lazy(() => import('./utils/scrollArrow'));

function App() {
    return (
        <div>
            <link rel="preload" as="image" href={logo}/>
            <Router>
                <Switch>
                    <Route path="/aboutus">
                        <AboutRoute/>
                    </Route>
                    <Route path="/contact">
                        <ContactRoute/>
                    </Route>
                    <Route path="/">
                        <HomeRoute/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;

function HomeRoute() {
    return (
        <div>
            <div className="hero-area-2">
                <Particles options={particlesOptions}/>
                <NavigationBar/>
                <Home/>
            </div>
            <Suspense fallback={<div>Loading ScrollArrow...</div>}>
                <ScrollArrow/>
            </Suspense>
            <Suspense fallback={<div>Loading AboutUs...</div>}>
                <AboutUsLazy/>
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <ServicesLazy/>
            </Suspense>
            <Suspense fallback={<div>Loading Clients...</div>}>
                <ClientsLazy/>
            </Suspense>
            <Suspense fallback={<div>Loading Contact...</div>}>
                <ContactLazy/>
            </Suspense>
            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer/>
            </Suspense>
        </div>
    )
}

function AboutRoute() {
    return (
        <div>
            <div>
                <NavigationBarSimple/>
                <AboutUs/>
            </div>
            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer/>
            </Suspense>
        </div>
    );
}

function ContactRoute() {
    return (
        <div>
            <div>
                <NavigationBarSimple/>
                <Contact/>
            </div>
            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer/>
            </Suspense>
        </div>
    );
}