import React from 'react';
import {withTranslation} from "react-i18next";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import './NavigationBar.css';

import logo from '../assets/logos/logo_principal.png';
import isologo from '../assets/logos/isologo.png';
import language from '../assets/img/language.png';

class NavigationBarSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "navbar-transparent",
            lang: this.props.i18n.language
        };
    }

    changeLanguage(lang) {
        this.props.i18n.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        this.setState({lang: lang});
    }

    render() {
        const {t} = this.props;
        return (
            <Navbar collapseOnSelect className={"fixed-top " + this.state.color} expand="md" bg="inverse"
                    variant="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Brand
                    href="/" className="page-scroll navbar-logo">
                    <img src={logo} height="55" alt="BPMX - Rethinking your process" className="hidden-mobile"/>
                    <img src={isologo} height="55" alt="BPMX - Rethinking your process" className="show-mobile"/>
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Container>
                        <Nav className="mr-auto full-width flex-end">
                            <Nav.Item>
                                <NavDropdown id="basic-nav-dropdown" title={
                                    <div style={{display: "inline-block"}}>
                                        <img className="language" src={language} alt="BPMX"/>
                                        {this.state.lang === "es" ? t('menu.spanish') : t('menu.english')}
                                    </div>
                                }>
                                    <NavDropdown.Item id="basic-nav-dropdown-item-es"
                                                      onClick={() => this.changeLanguage("es")}><img src={language}
                                                                                                     height="16"
                                                                                                     className="language"
                                                                                                     alt="BPMX"/>{t('menu.spanish')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item id="basic-nav-dropdown-item-en"
                                                      onClick={() => this.changeLanguage("en")}><img src={language}
                                                                                                     height="16"
                                                                                                     className="language"
                                                                                                     alt="BPMX"/>{t('menu.english')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withTranslation()(NavigationBarSimple);