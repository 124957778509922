import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//import HttpApi from 'i18next-http-backend';

import translationEsp from "../locales/es/translations.json";
import translationEng from "../locales/en/translations.json";

i18n
    .use(initReactI18next)
    // .use(HttpApi)
    .use(LanguageDetector)
    .init({
        debug: true,
        // lng: "en",
        fallbackLng: "en",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            es: {
                translations: translationEsp,
            },
            en: {
                translations: translationEng,
            },
        },
        ns: ["translations"],
        defaultNS: "translations",
    });

export default i18n;
